var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light fixed-top"},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item link",class:{ active: _vm.$route.path == '/' }},[_c('a',{staticClass:"nav-link",on:{"click":function($event){return _vm.home()}}},[_vm._v("Home")])]),_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",class:{
                active: _vm.$route.path == '/about' || _vm.$route.path == '/hse-policy' || _vm.$route.path == '/environmentalpolicy' || _vm.$route.path == '/communityaffairs'},attrs:{"href":"#","id":"navbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Who We Are ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.about()}}},[_vm._v("About Us")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.hse()}}},[_vm._v("HSE Policy")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.env()}}},[_vm._v("Environmental Policy")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.com()}}},[_vm._v("Community Affairs")])])]),_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",class:{
                active:
                  _vm.$route.path == '/construction' ||
                  _vm.$route.path == '/civilengineering' ||
                  _vm.$route.path == '/mechanicalengineering' ||
                  _vm.$route.path == '/procurement',
              },attrs:{"href":"#","id":"navbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Services ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.pro()}}},[_vm._v("Procurement")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.real()}}},[_vm._v("Real Estate & Building Construction")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.mech()}}},[_vm._v("Mechanical Engineering")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.civil()}}},[_vm._v("Civil Engineering")])])]),_c('li',{staticClass:"nav-item link",class:{ active: _vm.$route.path == '/projects' }},[_c('a',{staticClass:"nav-link",on:{"click":function($event){return _vm.projects()}}},[_vm._v("Projects")])]),_c('li',{staticClass:"nav-item link",class:{ active: _vm.$route.path == '/contactus' }},[_c('a',{staticClass:"nav-link",on:{"click":function($event){return _vm.contact()}}},[_vm._v("Contact Us")])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('span',{staticClass:"mr-2"},[_c('img',{attrs:{"src":require("@/assets/img/ELLALEON LOGO.jpg"),"height":"50px"}})]),_vm._v("EllaLeon.")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('i',{staticClass:"fas fa-bars"})])}]

export { render, staticRenderFns }